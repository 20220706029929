import React from "react";
import NavbarDriver from "./components/NavbarDriver";
import HeroSectionDriver from "./components/HeroSectionDriver";
import FeaturesSectionDriver from "./components/FeaturesSectionDriver";
import JobListingSectionDriver from "./components/JobListingSectionDriver";
import TestimonialsSectionDriver from "./components/TestimonialsSectionDriver";
import CTASectionDriver from "./components/CTASectionDriver";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <NavbarDriver />
      <HeroSectionDriver />
      <FeaturesSectionDriver />
      <JobListingSectionDriver />
      <TestimonialsSectionDriver />
      <CTASectionDriver />
      <Footer />
    </div>
  )
}

export default App;
