const JobListingSectionDriver: React.FC = () => {
    const jobs = [
        {title: "タクシードライバー", location: "東京都", salary: "年収500万円~"},
        {title: "トラックドライバー", location: "大阪府", salary: "年収600万円~"},
        {title: "バスドライバー", location: "福岡県", salary: "年収450万円~"}
    ]
    return(<section id="jobs" className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">募集中の職種</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {jobs.map((job, index) => (
            <div key={index} className="border p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">{job.title}</h3>
              <p>{job.location}</p>
              <p className="font-bold">{job.salary}</p>
              <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">詳細を見る</button>
            </div>
          ))}
        </div>
      </div>
    </section>
    )
}

export default JobListingSectionDriver;
