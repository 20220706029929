const TestimonialsSectionDriver: React.FC = () => {
  const testimonials = [
    { name: "山田 一郎", feedback: "このサイトのおかげで理想の職場に出会えました。感謝しています。" },
    { name: "田中 花子", feedback: "応募から採用までスムーズに進み、大変満足しています。" },
    { name: "佐藤 太郎", feedback: "新しいキャリアを始める勇気をくれました！ありがとうございます。" },
  ];

  return (
    <section id="testimonials" className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">転職成功者の声</h2>
        <div className="flex flex-col md:flex-row justify-around gap-6">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="md:w-1/3 p-4 mb-4 md:mb-0 border rounded-lg shadow-md">
              <p className="text-lg italic">"{testimonial.feedback}"</p>
              <p className="mt-4 text-gray-700">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TestimonialsSectionDriver;
