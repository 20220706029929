const FeaturesSectionDriver: React.FC = () => {
  const features = [
    { title: "高収入求人", description: "経験者優遇、高収入を目指せるポジション" },
    { title: "柔軟な勤務時間", description: "ライフスタイルに合わせた勤務時間を提供" },
    { title: "安心のサポート体制", description: "転職から入社後までサポートします" },
  ];

  return (
    <section id="features" className="py-12 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">ドライバー転職のメリット</h2>
        <div className="flex justify-around">
          {features.map((feature, index) => (
            <div key={index} className="w-1/3 p-4">
              <h3 className="text-xl font-semibold">{feature.title}</h3>
              <p className="mt-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturesSectionDriver;