import React, { useState } from 'react';

const NavbarDriver: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="flex justify-between items-center p-6 bg-white shadow-md">
      <div className="text-2xl font-bold">DriverJobs</div>
      <div className="hidden md:flex space-x-6">
        {/* 中画面以上では表示 */}
        <a href="#features" className="text-gray-700">特徴</a>
        <a href="#jobs" className="text-gray-700">求人情報</a>
        <a href="#process" className="text-gray-700">応募の流れ</a>
        <a href="#apply" className="text-white bg-blue-500 px-4 py-2 rounded">今すぐ応募</a>
      </div>
      <div className="md:hidden">
        {/* 小画面ではハンバーガーメニューを表示 */}
        <button onClick={toggleMenu} className="text-gray-700">
          {isOpen ? '✕' : '☰'}
        </button>
      </div>

      {isOpen && (
         <div className="absolute top-16 left-0 w-full bg-white shadow-lg flex flex-col items-center space-y-6 py-4 md:hidden">
         <a href="#features" className="text-gray-700">特徴</a>
         <a href="#jobs" className="text-gray-700">求人情報</a>
         <a href="#process" className="text-gray-700">応募の流れ</a>
         <a href="#apply" className="text-white bg-blue-500 px-4 py-2 rounded">今すぐ応募</a>
       </div>
      )}

    </nav>
  );
}

export default NavbarDriver;
