import React from 'react';

const HeroSectionDriver: React.FC = () => {
  return (
    <section className="h-screen bg-gray-100 flex flex-col md:flex-row justify-center items-center text-center md:text-left">
      {/* 左側にテキスト */}
      <div className="md:w-1/2 p-6">
        <h1 className="text-3xl md:text-5xl font-bold text-gray-900">
          あなたのキャリアをドライブしよう！
        </h1>
        <p className="mt-4 text-base md:text-lg text-gray-700">
          トラックドライバーとして充実した毎日を送りませんか？経験を積んで、あなたの道を切り開こう！
        </p>
        <button className="mt-6 bg-yellow-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-full hover:bg-yellow-600 transition duration-300">
          今すぐ応募
        </button>
      </div>

      {/* 右側に画像を表示 */}
      <div className="md:w-1/2 p-6">
        <img 
          src="/images/truck-driver.webp" 
          alt="充実して働くトラックドライバー" 
          className="rounded-lg shadow-lg object-cover w-full h-full md:h-auto"
        />
      </div>
    </section>
  );
}

export default HeroSectionDriver;
