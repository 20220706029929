const CTASectionDriver: React.FC = () => {
  return (
    <section id="apply" className="py-12 bg-blue-600 text-white text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-6">今すぐあなたのキャリアを一歩前進させましょう！</h2>
        <p className="text-lg mb-6">高収入なドライバーのポジションがあなたを待っています。簡単な登録で新しいキャリアをスタート！</p>
        <button className="bg-yellow-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-yellow-600 transition duration-300">
          無料で応募する
        </button>
      </div>
    </section>
  );
}

export default CTASectionDriver;
